import { mobxServices } from '../../services';

class RootService {
  services = mobxServices;

  constructor() {
    this.services.workspace.kickOutIfUnknown();
    this.services.access.loadFF();
  }
}

export const rootService = new RootService();

