import { ServiceKeys, ServiceReturnType } from './types';

export const useServices = <Names extends ServiceKeys>(services: Names[] = []) => {
  const servicesMap = services.reduce((acc, service) => {
    const serviceKey = `${service.charAt(0).toLowerCase()}${service.slice(1)}Service`;
    acc[serviceKey] = window.Rise.getService(service);

    return acc;
  }, {}) as ServiceReturnType<Names>;

  return servicesMap;
};
