import { featureFlagsStore } from './stores/featureFlagsStore';

import { riseCreating } from '../../utils/riser';

class Access {
  ffStore = featureFlagsStore;

  loadFF = async () => {
    this.ffStore.setFeatureFlagsLoading(true);

    try {
      await riseCreating();

      await window.Rise.getService('FeatureFlags').getAllFeatureFlags();
      const risePortFF = window.Rise.getService('FeatureFlags').get();

      if (risePortFF) {
        this.ffStore.setFeatureFlags(
          risePortFF,
        );
      }
    } catch (error) {
      this.ffStore.setFeatureFlagsLoading(false);

      return null;
    }

    this.ffStore.setFeatureFlagsLoading(false);

    return null;
  };
}

export const access = new Access();
