import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'sw-ui';

import { rootService } from '../../../core/business';

import COMPONENTS from '../../../../bi/constants/components';

const { getUserName, store } = rootService.services.workspace;

interface UserCardProps { mailClassName?: string }

export const UserCard = observer((
  { mailClassName }: UserCardProps,
) => {
  useEffect(() => {
    getUserName();
  }, []);

  return (
    <div className='sw-tooltip-wrapper'>
      <div className={ mailClassName }>
        { store.userName }
      </div>
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
      >
        { store.userName }
      </Tooltip>
    </div>
  );
});
