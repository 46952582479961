import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { clsx } from 'clsx';

import { rootService } from '../../core/business';

import { UserCard } from './components/UserCard';

import { stateVariants } from './const';

import { MenuItem } from './components/MenuItem';
import { SignOut } from './components/SignOut';

import styles from './styles.module.scss';

export const LeftSidebar = observer(() => {
  const {
    workspace: { store: { loadOldNavigation, appMenu } },
    access: { ffStore: { ffLoading } },
  } = rootService.services;

  useEffect(() => {
    if (!ffLoading) loadOldNavigation();
  }, [ffLoading]);

  // __lmOpened глобальное поле синхронизации со старой версией фронта.
  const [isOpen, setIsOpen] = useState(window.__lmOpened || false);

  useEffect(() => {
    window.__lmOpened = isOpen;
  }, [isOpen]);

  const currentState = stateVariants[String(isOpen)];

  return (
    <div
      className={ clsx(styles.container, currentState.className) }
      onMouseEnter={ () => setIsOpen(true) }
    >
      <div className={ styles.openerBtn } onClick={ () => setIsOpen(!isOpen) }>
        <i className='material-icons'>
          { currentState.arrow }
        </i>
      </div>
      <div className={ styles.logoContainer }>
        <img className={ styles.logo } src={ currentState.logoPath } alt='sw logo' />
        <UserCard mailClassName={ currentState.mailClassName } />
      </div>
      <div className={ styles.menuItems }>
        { appMenu.map((item, index) => (
          <MenuItem
            key={ index }
            { ...toJS(item) }
            isShort={ !isOpen }
          />
        )) }
      </div>
      <div className={ styles.bottom }>
        <SignOut isOpen={ isOpen } />
      </div>
    </div>
  );
});
