import { observer } from 'mobx-react-lite';
import { Checkbox } from 'sw-ui';
import { UUID } from 'node:crypto';

import { useServices } from 'v2/hooks/useServices';
import { rootService } from '../../../../../../core/business';

import DocumentIcon from '../../../../../../../components/documentIcon';
import { InputName } from '../InputName';

import styles from '../../styles.module.scss';
import { IRegistryConstructorTemplate } from '../../../../../../services/company/types';

interface ITemplateRowProps {
  row: IRegistryConstructorTemplate;
  onSelect: (value: UUID) => void;
  value: boolean;
}

export const TemplateRow = observer((
  {
    row, onSelect, value,
  }: ITemplateRowProps,
) => {
  const { updateTemplateName, documentTemplates: { loadingField } } = rootService.services.company;

  const { companyService } = useServices(['Company']);
  const { id } = companyService.get();

  const renderDocumentIcon = () => (
    row.FileTypeStrings.map((format: string) => (
      <DocumentIcon
        key={ format }
        type={ format }
        onClick={ () => rootService.services.company.getFilePreview(row.Id, format) }
      />
    ))
  );

  return (
    <tr>
      <td className={ styles.td }>
        <Checkbox
          onChange={ onSelect }
          value={ value }
        />
      </td>
      <td className={ styles.td }>
        <InputName
          onChange={ (name) => updateTemplateName(name, row.Id, id) }
          value={ row.Name }
          loading={ loadingField === row.Id }
        />
      </td>
      <td className={ styles.td }>
        <div className={ styles.iconCell }>
          { renderDocumentIcon() }
        </div>
      </td>
      <td className={ styles.td }>
        <div className={ styles.iconCell }>
          <DocumentIcon
            type='xlsx'
            onClick={ () => rootService.services.company.getTemplate(row.Id) }
          />
        </div>
      </td>
    </tr>
  );
});
