import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Dialog,
  Select,
  DatePicker,
  Button,
  Loading,
} from 'sw-ui';

import { useServices } from 'v2/hooks/useServices';
import { rootService } from '../../../../../../core/business';

import DecisionButtons from '../../../../../../../components/DecisionButtons';

import { formatDate, prevMonthFirstDay } from 'bi/utils/formatDate';
import { getArrayFileTypes } from '../../../../../../services/company/utils/documentTemplates';

import COMPONENTS from '../../../../../../../bi/constants/components';
import { DATE } from 'constants/time';
import { LABELS, FORMATS } from '../../consts';

import type { Moment } from 'moment';
import { UUID } from 'node:crypto';

import styles from '../../styles.module.scss';

const OPEN = 'open';
const LIMIT = {
  ONE: 1,
  THREE: 3,
};
const DIVIDER = ' и ';

interface ITemplateSettingsProps {
  onClose: (value: boolean) => void;
  isOpen: boolean;
}

export const TemplateSettings = observer((
  { onClose, isOpen }: ITemplateSettingsProps,
) => {
  const {
    loadTemplates,
    loadRegistries,
    checkRegistiries,
    addRegistry,
    updateRegistryDate,
    updateRegistryFileType,
    removeRegistry,
    documentTemplates: {
      templates,
      error,
      loading,
      registries,
    },
  } = rootService.services.company;

  const { companyService } = useServices(['Company']);
  const { id } = companyService.get();

  const [selectedTemplate, setSelectedTemplate] = useState<UUID>();
  const [startDay, setStartDay] = useState<Moment>();
  const [selectedFormat, setSelectedFormat] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [registryToDelete, setRegistryToDelete] = useState<UUID | null>(null);

  const isDisabled = registries.length === LIMIT.THREE || !selectedTemplate || !selectedFormat.length;

  const templatesList = templates.map(({ Id, Name }) => ({ label: Name, value: Id }));

  useEffect(() => {
    loadTemplates(id);
    loadRegistries(id);
  }, []);

  if (!isOpen) return null;

  const handleAddRegistry = () => {
    const correctDate = formatDate(startDay, DATE);
    const name = templates.find(({ Id }) => Id === selectedTemplate)?.Name as string;
    const fileType = getArrayFileTypes(selectedFormat);

    checkRegistiries(selectedTemplate as UUID);
    addRegistry({
      RegistryTemplateId: selectedTemplate as UUID,
      Date: correctDate,
      FileTypes: fileType,
      Name: name,
    }, id);
  };

  const handleRemoveRegistry = () => {
    if (!registryToDelete) return;

    setIsOpenModal(false);
    removeRegistry(registryToDelete, id);
    setRegistryToDelete(null);
  };

  const handleUpdateFileType = (constructedRegistryId: UUID, value: string) => {
    const fileTypes = getArrayFileTypes(value);
    updateRegistryFileType(constructedRegistryId, fileTypes, id);
  };

  const renderSettings = () => (
    <div className={ styles.periodForm }>
      <div className={ styles.column }>
        <div className={ styles.subtitle }>
          { LABELS.REGISTRY }
        </div>
        <Select
          label={ LABELS.SELECT_REGISTRY }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          value={ selectedTemplate }
          items={ templatesList }
          onChange={ ({ value }) => setSelectedTemplate(value) }
          className={ styles.customSelect }
        />
      </div>
      <div className={ `${styles.column} ${styles.dateContainer}` }>
        <div className={ styles.subtitle }>
          { LABELS.ADD_REGISTRY }
        </div>
        <DatePicker
          placeholder={ LABELS.FROM }
          label={ LABELS.FROM }
          value={ startDay }
          onChange={ ({ value }) => setStartDay(value) }
          inputTheme={ OPEN }
          minDate={ prevMonthFirstDay() }
        />
      </div>
      <div className={ styles.column }>
        <div className={ styles.subtitle }>
          { LABELS.FORMAT }
        </div>
        <Select
          label={ LABELS.SELECT_FORMAT }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          value={ selectedFormat }
          items={ FORMATS }
          onChange={ ({ value }) => setSelectedFormat(value) }
          className={ styles.customSelect }
        />
      </div>
      <div className={ styles.button }>
        <Button
          theme={ COMPONENTS.BUTTON.THEME.SECOND }
          onClick={ handleAddRegistry }
          disabled={ isDisabled }
        >
          { LABELS.ADD }
        </Button>
      </div>
    </div>
  );

  const renderModal = () => {
    if (!isOpenModal) return null;

    return (
      <Dialog onClick={ () => setIsOpenModal(false) } width={ COMPONENTS.DIALOG.WIDTH.SMALL }>
        <div className={ styles.modal_wrap }>
          <div>{ LABELS.ERRORS.WARNING }</div>
          <DecisionButtons
            labelSave={ LABELS.YES }
            labelCancel={ LABELS.NO }
            onCancel={ () => setIsOpenModal(false) }
            onSave={ () => handleRemoveRegistry() }
          />
        </div>
      </Dialog>
    );
  };

  const renderRegistries = () => (
    <div className={ styles.periodsTable }>
      <div className={ styles.subtitle }>
        { LABELS.ADDED_REGISTRIES }
      </div>
      <hr className={ styles.table } />
      { loading
        ? <Loading />
        : (
          registries.map(({
            Name,
            Date,
            FileTypes,
            RegistryTemplateId,
            ConstructedRegistryId,
          }) => {
            const fileType = FileTypes.length > LIMIT.ONE ? FileTypes.join(DIVIDER) : FileTypes[0];

            return (
              <div key={ RegistryTemplateId }>
                <div className={ `${styles.periodForm} ${styles.row}` }>
                  <div className={ styles.column }>
                    <div className={ styles.value }>
                      { Name }
                    </div>
                  </div>
                  <div className={ styles.dateContainer }>
                    <DatePicker
                      placeholder={ LABELS.FROM }
                      label={ LABELS.FROM }
                      value={ Date }
                      onChange={ ({ value }) => updateRegistryDate(ConstructedRegistryId, formatDate(value, DATE), id) }
                      inputTheme={ OPEN }
                      minDate={ prevMonthFirstDay() }
                    />
                  </div>
                  <div className={ styles.column }>
                    <Select
                      label={ LABELS.SELECT_FORMAT }
                      theme={ COMPONENTS.SELECT.THEME.BORDER }
                      value={ fileType }
                      items={ FORMATS }
                      onChange={ ({ value }) => handleUpdateFileType(ConstructedRegistryId, value) }
                      className={ styles.customSelect }
                    />
                  </div>
                  <div>
                    <Button
                      onClick={ () => {
                        setRegistryToDelete(ConstructedRegistryId);
                        setIsOpenModal(true);
                      } }
                      className={ styles.button }
                    >
                      { LABELS.REMOVE }
                    </Button>
                  </div>
                </div>
                <hr className={ styles.table } />
                { renderModal() }
              </div>
            );
          })
        ) }
    </div>
  );

  const renderError = () => {
    if (!error) return null;

    return (
      <div className={ styles.errorMessage }>
        { error }
      </div>
    );
  };

  return (
    <Dialog onClick={ onClose } showCloseButton>
      <div className={ styles.viewContainer }>
        <div className={ styles.title }>
          { LABELS.PERIOD_SETTINGS_NAME }
        </div>
        { renderSettings() }
        { renderError() }
        { renderRegistries() }
      </div>
    </Dialog>
  );
});
