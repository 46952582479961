export const WIKI_LINK = 'https://docs.google.com/spreadsheets/d/1I52ZOMFvhB3C5ow6-JqH3dN87MHspNR-HMErHgCN2LI/edit?usp=sharing';

export const LABELS = {
  SETTINGS_NAME: 'Настройки шаблонов реестров',
  PERIOD_SETTINGS_NAME: 'Настройки реестров для ЗД',
  VIEW_TITLE: 'Шаблоны реестров',
  OPEN_WIKI: 'Открыть инструкцию',
  UPLOAD_TEMPLATE: 'Загрузить шаблон',
  REMOVE_TEMPLATE: (many = false) => `Удалить шаблон${many ? 'ы' : ''}`,
  REMOVE: 'Удалить',
  COLUMN_NAMES: {
    UPLOAD: 'Загруженные реестры',
    DOWNLOAD_PREVIEW: 'Скачать файл с предпросмотром',
    DOWNLOAD_FORM: 'Скачать форму реестра',
  },
  ERRORS: {
    TEMPLATE_LIMIT: 'Превышен лимит сохраняемых шаблонов реестров. Удалите ранее сохраненный шаблон',
    SYMBOLS_LIMIT: 'Ограничение 200 символов',
    EMPTY_NAME: 'Название не может быть пустым',
    ALREADY: 'Реестр с таким наполнением уже добавлен',
    WARNING: 'Вы точно хотите удалить реестр из пакета ЗД? Удаленный реестр не будет учитываться в последующих пакетах документов.',
  },
  REGISTRY: 'Реестр',
  SELECT_REGISTRY: 'Выберите реестр',
  SELECT_FORMAT: 'Выберите формат',
  ADD_REGISTRY: 'Добавить реестр в ЗД',
  FORMAT: 'Формат реестра',
  FROM: 'с',
  ADD: 'Добавить',
  ADDED_REGISTRIES: 'Добавленные реестры',
  YES: 'Да',
  NO: 'Нет',
};

export const FORMATS = [
  { label: 'pdf', value: 'pdf' },
  { label: 'xlsx', value: 'xlsx' },
  { label: 'pdf и xlsx', value: 'pdf и xlsx' },
];

export enum ModalType {
  templates = 'templates',
  periods = 'periods',
}

export const COLUMN_LIST = Object.values(LABELS.COLUMN_NAMES);
