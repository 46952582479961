import { Develop } from './Develop';

export const ROUTE_INFO_V2 = {
  develop: {
    path: '/v2/dev-page',
    Component: Develop,
  },
};

export const ROUTE_LIST = Object.values(ROUTE_INFO_V2);
