import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'sw-ui';

import { rootService } from '../../../../core/business';

import { Templates } from './components/Templates';
import { TemplateSettings } from './components/TemplateSettings';

import { useServices } from 'v2/hooks/useServices';

import { getCurrentDatePlusYear } from '../../../../../../src/utils/date';

import COMPONENTS from 'bi/constants/components';
import { LABELS, ModalType } from './consts';

import styles from './styles.module.scss';

const { FLAT } = COMPONENTS.BUTTON.THEME;

export const DocumentTemplates = observer(() => {
  const [modal, setModal] = useState<ModalType | null>(null);
  const {
    companyService: {
      documents: { loadDocuments },
      get,
    },
  } = useServices(['Company']);
  const { startDate } = get();

  useEffect(() => {
    if (!modal) loadDocuments(startDate, getCurrentDatePlusYear());
  }, [modal]);

  if (!rootService.services.access.ffStore.ffList.RegistryConstructor) return null;

  const renderModal = () => {
    switch (modal) {
      case ModalType.templates:
        return <Templates isOpen={ true } onClose={ () => setModal(null) } />;
      case ModalType.periods:
        return <TemplateSettings isOpen={ true } onClose={ () => setModal(null) } />;
      default:
        return null;
    }
  };

  return (
    <div className={ styles.btnContainer }>
      <Button theme={ FLAT } onClick={ () => setModal(ModalType.templates) }>
        { LABELS.SETTINGS_NAME }
      </Button>
      <Button theme={ FLAT } onClick={ () => setModal(ModalType.periods) }>
        { LABELS.PERIOD_SETTINGS_NAME }
      </Button>
      { renderModal() }
    </div>
  );
});
